@import "cssreset.css";
//@import "~normalize.css/normalize.css";
//@import url(https://fonts.googleapis.com/css2?family=Archivo:wght@300;400;500;600;700);
//@import "~@fontsource/gothic-a1/index.css";
//@import url(https://fonts.googleapis.com/css2?family=Gothic+A1:wght@500;600;800;900&display=swap);

$color: #ffffff;
$linkHoverColor: #fff;
$bgColor: #0d0d0c;
$boderColor: #535353;
$bodyPadding: 2rem;
$topPadding: $bodyPadding/2;
$contentWidth: 30vw;
$menuPointVerticalPadding: 1rem;
$topAxis: 6.5rem; // used by heading and menu DIMENSIONS

$font: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
//$font: "Gothic A1";
$fontWeight: 400; //300; //500;
$headerWeight: 500; //700;

$inactiveMenuFontAlpha: 0.2;
$inactiveMenuBorderAlpha: 0.08;

$contentFontSize: 1.6vw;
$menuFontSize: $contentFontSize;

@mixin default-background($color: $bgColor) {
  // add noise to background
  background-color: $color;
  background-image: url(./images/noise_pattern_1.jpeg);
  background-size: 4rem;
  background-blend-mode: difference;
}

html,
body {
  // improve text rendering osx
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale; /* Firefox */

  text-rendering: optimizeLegibility;

  font-feature-settings: "kern" 1;
  font-kerning: normal;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  height: 100%; // fuck it --> https://stackoverflow.com/questions/8468066/child-inside-parent-with-min-height-100-not-inheriting-height/8468131#8468131
}

body {
  font-family: $font, sans-serif;
  font-weight: $fontWeight;
  color: $color;

  // add noise to background
  @include default-background();

  overflow: hidden;

  h1,
  h2 {
    font-weight: $headerWeight;
    font-size: 1.6em;
  }
  p {
    margin-bottom: 1em;
    line-height: 1.2em;
  }
}

// complicated font size
@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }

      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 24px;

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

#wrapper {
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  // fade in content (trigger from DOM loaded JS)
  transition: opacity 0.2s ease-in;
  //transition-delay: 0.8s;

  opacity: 0;
  &.active {
    opacity: 1;
  }
}

#content__container {
  .content__page {
    .content__slides-wrapper {
      position: relative;
    }
    .content__slide {
      position: absolute;
      width: 100%;
      opacity: 0;
      transition: opacity 0.2s linear;
      pointer-events: none; // do not catch any clicks

      &.active {
        pointer-events: all;
        opacity: 1;
        z-index: 1;
      }

      .more-button {
        display: block;
        margin-top: 2rem;
        color: rgba(255, 255, 255, 0.5);
        img {
          // arrow
          height: 0.8rem;
          margin-bottom: -0.15rem;
          margin-right: 0.1rem;
          opacity: 0.5;
        }
      }
    }

    // content headings
    h2,
    h3,
    h4,
    h5 {
      font-size: 1rem;
      font-weight: $headerWeight;
      margin-bottom: 0.25rem;
    }
  }
}

/* slider shings */
.swiper-container {
  width: 100%;
  height: 100%;
}

$defaultMenuColor: rgba(255, 255, 255, $inactiveMenuFontAlpha);

#menu {
  display: flex;
  user-select: none; /* standard syntax */

  box-shadow: 5px 0px 15px 0px rgba(0, 0, 0, 0.1);

  .menu__point {
    font-weight: 500;
    cursor: pointer;
    writing-mode: vertical-rl;
    white-space: nowrap;

    display: flex;
    align-items: center;
    width: 1.2rem; // need a width on those menu points, otherwise parent wont grow..
    $centerPadding: 0.25rem;
    padding: $topPadding $centerPadding 0 $centerPadding * 2;

    @include default-background;

    // align title on baseline!
    .title {
      //display: flex;
      //flex-basis: $topAxis;
      //justify-content: flex-end;
    }

    // for editions
    span.issue {
      margin-bottom: 0.4rem;
    }

    border-right: 1px solid rgba($color, $inactiveMenuBorderAlpha);
    &:first-of-type {
      padding-left: 0.5rem;
      border-left: 1px solid rgba($color, $inactiveMenuBorderAlpha);
    }

    & > * {
      margin: 0;
      padding-bottom: $menuPointVerticalPadding;
      // margin-top: -10px;
    }
    & > a {
      padding-bottom: $menuPointVerticalPadding / 2;
    }

    .title,
    a {
      color: rgba(255, 255, 255, $inactiveMenuFontAlpha);
      transition: color 0.2s linear;
    }

    a {
      text-decoration: none;
      width: 100%;
      display: flex;
      align-items: center;

      // close X
      img {
        transition: opacity 0.2s linear;
        width: 0.6rem;
        margin-top: 0.5rem;
        margin-bottom: 0.3rem;
        opacity: $inactiveMenuFontAlpha;
      }
    }

    &.hovered {
      .title {
        color: #fff;
      }
      & > a:first-of-type {
        color: #fff;
      }
      .slide-indicator-dot {
        ///background-color: rgba(255, 255, 255, $inactiveMenuFontAlpha);
      }

      // special case curated editions
      // highlight the second (because first is hidden)
      &.uid__curated-editions {
        & > a:nth-of-type(2) {
          color: #fff;
        }
      }
    }

    &.active.has-slides,
    &.active.has-slides a {
      cursor: e-resize;
    }

    &.hovered-direkt-link {
      & > a:first-of-type {
        color: $defaultMenuColor;
      }
      a.hovered {
        color: #fff;
        img {
          opacity: 1;
        }
      }
    }

    // default highlight of active page on parent and child
    &.active {
      .title,
      a.active {
        color: #fff;
      }
    }

    &.hovered:not(.active) {
      .slide-indicator-dot:first-of-type {
        background-color: #fff !important;
      }
    }

    // do not highligh active slide
    &:not(.active) {
      .slide-indicator-dot.active {
        background-color: rgba(255, 255, 255, $inactiveMenuFontAlpha);
      }
    }

    img {
      width: 0.6rem;
      margin-top: 0.5rem;
      margin-bottom: 0.3rem;
      opacity: 0.2;
    }
  }

  .menu__point.edition {
    .title,
    a {
      color: #000;
    }
    .slide-indicator-dot {
      //background-color: rgba(0, 0, 0, $inactiveMenuFontAlpha);
      background-color: #000;
      &.active {
        background-color: #fff;
      }
    }
    &.hovered,
    &.active {
      .title,
      a {
        color: white;
      }
    }

    &:not(.active) {
      .slide-indicator-dot.active {
        //background-color: rgba(0, 0, 0, $inactiveMenuFontAlpha);
        background-color: #000;
      }
      &.hovered {
        .slide-indicator-dot:first-of-type {
          background-color: #fff;
        }
      }
    }
  }
}

// curated editions view
body:not(.curated-editions__active) {
  .menu__point.uid__curated-editions a.editions__close-menu,
  .menu__point.uid__curated-editions a.uid__curated-editions {
    display: none !important;
  }
  // .menu__point.uid__curated-editions a.editions__open-menu {
  //   display: flex !important;
  // }
}

body.curated-editions__active {
  .menu__point {
    display: none !important;
  }

  .menu__point.uid__home,
  .menu__point.uid__curated-editions,
  .menu__point.edition {
    display: flex !important;
  }

  .menu__point.uid__curated-editions {
    //background: yellow;
    .slide-indicators__wrapper {
      display: none;
    }
    a.editions__close-menu {
      display: flex;
    }
    a.editions__open-menu {
      display: none !important;
    }
  }
}

.menu__point.curated-editions {
  a.editions__close-menu {
    display: none !important;
  }
}
.menu__point.edition {
  display: none !important;
}

$indicatorSize: 0.3rem;
.slide-indicators__wrapper {
  display: inline-block;
  margin-left: 0.1rem;
  .slide-indicator-dot {
    display: inline-block;
    background-color: rgba(255, 255, 255, $inactiveMenuFontAlpha);
    transition: background-color 0.2s ease-in-out;
    width: $indicatorSize;
    height: $indicatorSize;
    margin-right: $indicatorSize * 0.8;
    margin-bottom: $indicatorSize;
    &.active {
      background-color: #fff;
    }
  }
}

#content__wrapper {
  display: flex;
  justify-content: flex-end;
  flex: 1;

  body.has-slides & {
    cursor: e-resize;
  }
}

#content__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  //width: $contentWidth;
  margin-right: 8vh;
  flex-grow: 0.5;
  max-width: 20rem;

  .slide-indicators__wrapper {
    //display: none;
  }
}

#content__container {
  a {
    color: $color;
    text-decoration-line: underline;
    text-decoration-thickness: initial;
    text-decoration-style: initial;
    text-decoration-color: rgba($color, 0.5);
    text-decoration-thickness: 0.1em;
  }
  // works but ugly
  br + a {
    margin-top: 0.6rem;
  }

  // no margin for first p
  p:first-of-type {
    margin-top: 0;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
}

#burger {
  display: none;
  img {
    width: 0.7rem;
  }
}

#content__wrapper {
  overflow-y: auto;
}

.content__page {
  display: flex;
  flex-direction: column;
  flex: 1;

  .slide-indicators__wrapper {
    //display: none;
    //margin-top: $bodyPadding / 2;
  }

  .content__title {
    flex-basis: $topAxis;
    padding-top: $bodyPadding;
    position: relative;
    h1 {
      position: absolute;
      bottom: -0.2rem; // adjust to be optical on line with DIMENSIONS menu bottom
      //white-space: nowrap; // TODO: try on smaller screens
    }
    margin-bottom: 2rem;
  }

  .content__slides-wrapper {
    flex: 1;
  }
  .content__footer {
    font-size: 0.8rem;
    //opacity: 0.8;
    padding: $bodyPadding * 2 0 $bodyPadding;
  }
}

@media only screen and (max-width: 600px) {
  #burger {
    display: block;
    position: fixed;
    z-index: 9999;
    right: 0;
    top: 0;
    padding: 2.5rem;
    .burger__menu-icon,
    .burger__close-icon {
      position: absolute;
      right: $bodyPadding;
      top: $bodyPadding;
    }
    .burger__close-icon {
      display: none;
    }
  }

  #menu {
    //display: none;
    opacity: 0;
    transition: opacity 0.3s linear;
    transition-delay: 0.5s;

    pointer-events: none;
    touch-action: none;

    position: absolute;
    width: 100vw;
    height: 100vh;
    background: $bgColor;
    z-index: 1000;

    body.mobile-menu__open & {
      transition-delay: 0s !important;
      //background: yellow;
    }
  }

  #content__wrapper {
    flex-basis: 100%; // full width
    justify-content: unset;
    padding: 0 $bodyPadding;
  }
  #content__container {
    flex-basis: 100%;
    margin-right: unset;
  }

  // do not right align in mobile...
  .content__page .content__title {
    padding-top: $bodyPadding;
    //flex-basis: unset;

    .slide-indicators__wrapper {
      display: block;
      //position: absolute;
      //top: 1.8rem;
      margin-bottom: 1rem;
    }
    h1 {
      //position: unset !important;
      right: unset !important;
      direction: unset !important;
    }
  }

  body.mobile-menu__open {
    #burger {
      .burger__menu-icon {
        display: none;
      }
      .burger__close-icon {
        display: block;
      }
    }

    #menu {
      opacity: 1;
      //transition-delay: 0.5s; // open immediately

      // restore
      pointer-events: all;
      touch-action: all;
    }
  }

  #info {
    display: none;
  }
}

#info {
  padding: 0.6rem;
  //background: $bgColor;
  position: fixed;
  z-index: 9999;
  font-size: 0.7rem;
  pointer-events: none;
  //writing-mode: vertical-rl;
  //text-shadow: 2px 2px 5px #000, -2px -2px 5px #000, 2px -2px 5px #000, -2px 2px 5px #000;
}

// contact form honey pot
.honeypot {
  position: absolute;
  left: -9999px;
}

.contact-form {
  .group {
    //margin-bottom: 20px;

    input,
    label,
    textarea {
      box-sizing: border-box;
      margin: 0;
      padding: 0.4rem 0.6rem;
      border: 1px solid rgba(255, 255, 255, $inactiveMenuFontAlpha);
      background: none;
      display: block;
      width: 100%;
      font-size: inherit;
      color: #fff;
    }
    input.submit {
      width: unset;
    }
    label {
      display: none;
    }
    span {
      display: block;
      padding: 0.4rem 0.6rem;
      //margin-top: 0.5rem;
    }
  }
}
